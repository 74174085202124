import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    this.panelTargets.forEach(panel => panel.classList.add("hidden"))
  }

  // Entering: "transition ease-out duration-200"
  //   From: "opacity-0"
  //   To: "opacity-100"
  // Leaving: "transition ease-in duration-150"
  //   From: "opacity-100"
  //   To: "opacity-0"
  showPanel(event) {
    event.preventDefault()
    const targetPanel = event.target.parentElement.parentElement.querySelector("[data-tabs-target='panel']")
    if (targetPanel.classList.contains("hidden")) {
      this.panelTargets.forEach(panel => panel.classList.add("hidden"))
      targetPanel.classList.remove("hidden")
    } else {
      targetPanel.classList.add("hidden")
    }
  }
}






