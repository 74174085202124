import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-toggle"
export default class extends Controller {
  static targets = ["show", "hide", "list"]

  toggle() {
    this.showTarget.classList.toggle("hidden")
    this.hideTarget.classList.toggle("hidden")
    this.listTarget.classList.toggle("hidden")
  }
}
