import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-cart-item-quantity"
export default class extends Controller {
  static values = { updateUrl: String }
  update() {
    fetch(this.updateUrlValue, {
      method: "PATCH",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify({ quantity: this.element.selectedOptions[0].value })
    }).then(response => {
      if (response.ok) {
        // Trigger a full page refresh
        window.location.reload();
      } else {
        console.error("Failed to update cart item");
      }
    })
      .catch(error => console.error("Error:", error));
  }
}
