import { StreamActions } from "@hotwired/turbo"
import Toastify from "toastify-js"

StreamActions.toast = function () {
  const message = this.getAttribute("message")
  const position = this.getAttribute("position")
  const status = this.getAttribute("status")
  let backgroundColorHex = null

  switch (status) {
    case "success":
      backgroundColorHex = "#4CAF50";
      break;
    case "error":
      backgroundColorHex = "#F44336";
      break;
    case "warning":
      backgroundColorHex = "#FFC107";
      break;
    case "info":
      backgroundColorHex = "#2196F3";
      break;
    default:
      backgroundColorHex = "#333333";
  }

  Toastify({
    text: message,
    duration: 3000,
    destination: "",
    close: true,
    gravity: "top",
    position: position,
    stopOnFocus: true,
    style: {
      background: `linear-gradient(to right, ${backgroundColorHex}, ${backgroundColorHex})`,
    }
  }).showToast()
}
