import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["checkbox"]
  connect() {
  }

  refresh() {
    this.element.submit()
  }
}
