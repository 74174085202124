import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "form"]

  toggle(event) {
    event.preventDefault()
    console.log("toggle")
    const mobileLogo = document.getElementById("mobile_logo")
    mobileLogo.classList.toggle("hidden")
    if (this.hasContainerTarget) {
      this.containerTarget.classList.toggle("lg:block")
    }
    if (this.hasFormTarget) {
      this.formTarget.classList.toggle("hidden")
      this.formTarget.querySelector("input[type='text']").focus()
    }
  }

  search(event) {
    // listen for enter key
    if (event.key === "Enter") {
      console.log("search")
      const query = event.target.value
      fetch(`/search.json?query=${query}`)
        .then(response => response.json())
        .then((data) => {
          this.containerTarget.innerHTML = data.results
        })
    }
  }
}
